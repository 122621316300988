<template>
  <div class="my-favorites">
    <!-- 分类 -->
    <!-- <div class="my-favorites-sort">
      <div class="my-favorites-sortLeft">
        <div>
          <div>作品</div>
          <div>0</div>
        </div>
        <div>
          <div>素材</div>
          <div>0</div>
        </div>
      </div>
      <div class="my-favorites-sortRight">
        <div>
          <div>标题</div>
          <div>
            <input type="text" v-model="titleInput" placeholder="请输入关键字进行搜索" />
          </div>
          <div v-show="titleInput" @click="clearInput()">
            <i class="el-icon-error"></i>
          </div>
          <div>
            <i class="el-icon-search"></i>
          </div>
        </div>
      </div>
    </div> -->
    <!-- 类型 -->
    <!-- <div class="my-favorites-type">
      <div class="my-favorites-typeLeft">
        <div
          @click="selectTab(index)"
          :style="typeStatus == index ? 'background-color: #f1dc64;' : ''"
          class="my-favorites-typeLeft_item"
          v-for="(item,index) in typeList"
          :key="index"
        >
          <div>{{item.title}}</div>
          <div>{{item.num}}</div>
        </div>
      </div>
      <div class="my-favorites-typeRight">
        <div>
          <div>
            <input type="checkbox" />
          </div>
          <div>全部</div>
        </div>
        <div>
          <el-dropdown>
            <span class="el-dropdown-link">
              下拉菜单
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>黄金糕</el-dropdown-item>
              <el-dropdown-item>狮子头</el-dropdown-item>
              <el-dropdown-item>螺蛳粉</el-dropdown-item>
              <el-dropdown-item disabled>双皮奶</el-dropdown-item>
              <el-dropdown-item divided>蚵仔煎</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div> -->
    <!-- 内容 -->
    <div>
      <!-- 列表内容 -->
      <div class="card_list" @scroll.passive="getScroll($event)">
        <!-- 列表内容项 -->
        <div
          class="card_list_item"
          v-for="(item,index) in tabList"
          :key="index"
          @click="openDetails(item.id)"
        >
          <!-- 卡片图片 -->
          <div class="card_list_itemImg">
            <img :src="item.cover" />
          </div>
          <!-- 卡片内容 -->
          <div class="card_list_itemContent">
            <div class="card_list_itemContentText">{{item.title}}</div>
            <!-- 卡片标题 -->
            <!-- <el-popover placement="top-start" width="200" trigger="hover" :open-delay="500">
            <user-model></user-model>-->
            <!-- 发布人 -->
            <!-- <div class="card_list_itemContentUser" slot="reference">
              <div>
                <img :src="item.picture" />
              </div>
              <div>{{item.username}}</div>
            </div> -->
            <!-- </el-popover> -->
          </div>
          <!-- 卡片数据 -->
          <div class="card_list_itemData">
            <div>
              <div>
                <i class="el-icon-view"></i>
              </div>
              <div>{{item.visit_num}}</div>
            </div>
            <div>
              <div>
                <i class="iconfont icondianzan"></i>
              </div>
              <div>{{item.like_num}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="display: flex; align-items: center; justify-content: center">
      <el-pagination background layout="prev, pager, next" :total="count" :page-size="10" @current-change="currentChange">
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  props: ["tabList","count"],
  data() {
    return {
      titleInput: "",
      typeList: [
        {
          title: "全部",
          num: 0,
        },
        {
          title: "平面设计",
          num: 0,
        },
        {
          title: "三维设计",
          num: 0,
        },
        {
          title: "策划方案",
          num: 0,
        },
        {
          title: "视频作品",
          num: 0,
        },
      ],
      typeStatus: 0,
    };
  },
  methods: {
    clearInput() {
      this.titleInput = "";
    },
    selectTab(index) {
      this.typeStatus = index;
    },
    // 打开详情
    openDetails(e) {
      this.$router.push({
        path: "/HomeDetails",
        query:{
          id: e
        }
      });
    },
    // 获取滚动条
    getScroll(event) {
      let scrollBottom =
        event.target.scrollHeight -
        event.target.scrollTop -
        event.target.clientHeight;
      if(scrollBottom == 0){
        this.$emit('getScroll');
      }
    },
    // 分页切换
    currentChange(e) {
      this.$emit('pagingSwitch',e)
    },
  },
};
</script>

<style scoped>
/* 分类 */
.my-favorites-sort {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  height: 50px;
  padding: 10px 20px;
  background-color: #e5e5e5;
}
.my-favorites-sort > div {
  height: 100%;
  width: 100%;
}
.my-favorites-sortLeft {
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.my-favorites-sortLeft > div {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.my-favorites-sortLeft > div > div {
  margin: 0 5px;
  cursor: pointer;
}
.my-favorites-sortRight {
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.my-favorites-sortRight > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 30px;
  background-color: #fff;
  height: 35px;
  width: 75%;
}
.my-favorites-sortRight > div > div {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.my-favorites-sortRight > div > div:nth-of-type(1) {
  padding: 0 10px;
  /* width: 12%; */
}
.my-favorites-sortRight > div > div:nth-of-type(2) {
  width: 60%;
}
.my-favorites-sortRight > div > div:nth-of-type(3) {
  color: #a1a1a1;
  margin: 0 5px 0 0;
}
.my-favorites-sortRight > div > div:nth-of-type(4) {
  padding: 0 20px;
  color: #fff;
  background-color: #949494;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}
.my-favorites-sortRight > div > div:nth-of-type(4):active {
  background-color: #949494be;
}
.my-favorites-sortRight input {
  outline: none;
  border: none;
  height: 100%;
  font-size: 14px;
}

/* 类型 */
.my-favorites-type {
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
}
.my-favorites-typeLeft {
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.my-favorites-typeLeft > div {
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.my-favorites-typeLeft_item {
  cursor: pointer;
  background-color: #e5e5e5;
  text-align: center;
  border-radius: 3px;
}
.my-favorites-typeLeft_item > div {
  margin: 0 5px;
}
.my-favorites-typeRight {
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.my-favorites-typeRight > div {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
  cursor: pointer;
}
.my-favorites-typeRight > div:nth-of-type(1) > div {
  margin: 0 5px;
}

/* 卡片内容列表 */
.card_list {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.card_list_item {
  width: 29%;
  font-size: 14px;
  border-radius: 10px;
  margin: 1% 2%;
  box-sizing: border-box;
  background-color: #fff;
  transition: box-shadow 0.5s;
}
.card_list_item:hover {
  box-shadow: 7px 7px 7px #e3e3e3, -7px -7px 7px #f4f4f4;
}
.card_list_itemImg {
  width: 100%;
  height: 20vh;
}
.card_list_itemImg > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s;
  border-radius: 10px;
}
.card_list_itemImg > img:hover {
  transform: scale(1.5);
}
.card_list_itemContent {
  cursor: pointer;
  box-sizing: border-box;
  margin: 15px;
  border-bottom: 1px solid #e3e3e3;
}
.card_list_itemContentText {
  box-sizing: border-box;
  padding: 20px 10px;
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
}
.card_list_itemContentUser {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.card_list_itemContentUser > div {
  box-sizing: border-box;
  margin: 20px 10px;
}
.card_list_itemContentUser img {
  border-radius: 100%;
  width: 30px;
  height: 30px;
}
.card_list_itemData {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.card_list_itemData > div {
  display: flex;
  align-items: center;
  justify-content: center;
}
.card_list_itemData {
  padding: 20px;
  color: #666;
  box-sizing: border-box;
}
.card_list_itemData i {
  font-size: 20px;
  margin: 0 10px;
  box-sizing: border-box;
}
</style>