<template>
  <div class="my-works">
    <!-- 审核 -->
    <!-- <div class="my-works-review">
      <div class="my-works-reviewLeft">
        <div v-for="(item,index) in reviewList" :key="index">
          <div>{{item.name}}</div>
          <div>{{item.num}}</div>
        </div>
      </div>
      <div class="my-works-reviewRight">
        <div>
          <div>标题</div>
          <div>
            <input type="text" v-model="titleInput" placeholder="请输入关键字进行搜索" />
          </div>
          <div v-show="titleInput" @click="clearInput()">
            <i class="el-icon-error"></i>
          </div>
          <div>
            <i class="el-icon-search"></i>
          </div>
        </div>
      </div>
    </div> -->
    <!-- 类型 -->
    <!-- <div class="my-works-type">
      <div class="my-works-typeLeft">
        <div
          @click="selectTab(index)"
          :style="typeStatus == index ? 'background-color: #f1dc64;' : ''"
          class="my-works-typeLeft_item"
          v-for="(item,index) in typeList"
          :key="index"
        >
          <div>{{item.title}}</div>
          <div>{{item.num}}</div>
        </div>
      </div>
      <div class="my-works-typeRight">
        <div>
          <div>
            <input type="checkbox" />
          </div>
          <div>全部</div>
        </div>
        <div>
          <el-dropdown>
            <span class="el-dropdown-link">
              下拉菜单
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>黄金糕</el-dropdown-item>
              <el-dropdown-item>狮子头</el-dropdown-item>
              <el-dropdown-item>螺蛳粉</el-dropdown-item>
              <el-dropdown-item disabled>双皮奶</el-dropdown-item>
              <el-dropdown-item divided>蚵仔煎</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div> -->
    <!-- 内容 -->
    <div>
      <!-- 列表内容 -->
      <div class="card_list" @scroll.passive="getScroll($event)">
        <!-- 列表内容项 -->
        <div
          class="card_list_item"
          v-for="(item, index) in tabList"
          :key="index"
          @click="openDetails(item.id)"
        >
          <!-- 卡片图片 -->
          <div class="card_list_itemImg">
            <img :src="item.cover" />
          </div>
          <!-- 卡片内容 -->
          <div class="card_list_itemContent">
            <!-- 卡片标题 -->
            <div class="card_list_itemContentText">
              {{ item.title }}
            </div>
          </div>
          <!-- 卡片数据 -->
          <div class="card_list_itemData">
            <div>
              <div>
                <i class="el-icon-view"></i>
              </div>
              <div>{{ item.visit_num }}</div>
            </div>
            <div>
              <div>
                <i class="iconfont icondianzan"></i>
              </div>
              <div>{{ item.like_num }}</div>
            </div>
          </div>
          <!-- 卡片按钮 -->
          <div class="card_list_itemContent_btn">
            <div class="left_edit" @click.stop="openEdit(item)">
              <button>编辑</button>
            </div>
            <div class="right_delete" @click.stop="delMyPlan(item.id)">
              <button>删除</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="display: flex; align-items: center; justify-content: center">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="count"
        :page-size="10"
        @current-change="currentChange"
      >
      </el-pagination>
    </div>
    <div>
      <el-dialog :visible.sync="dialogFormVisible">
        <div>
          <!-- 方案标题 -->
          <div>
            <div class="plan_title">方案标题 |</div>
            <el-input placeholder="请输入方案标题" v-model="editInfo.title">
            </el-input>
          </div>
          <!-- 方案分类 -->
          <div class="editSort">
            <div class="plan_title">方案分类 |</div>
            <div
              class="editSort_item"
              v-for="(item, index) in editSort"
              :key="index"
            >
              <div class="edit_title">{{ item.title }}：</div>
              <div class="editSort_items">
                <div
                  :style="list.status ? 'background-color: #fdd30f' : ''"
                  class="editSort_item_content"
                  v-for="(list, key) in item.children"
                  :key="key"
                  @click="editSortSelect(index, key)"
                >
                  {{ list.title }}
                </div>
              </div>
            </div>
          </div>
          <!-- 方案简介 -->
          <div>
            <div class="plan_title">方案简介 |</div>
            <el-input
              type="textarea"
              :autosize="{ minRows: 4, maxRows: 4 }"
              placeholder="请输入简介"
              v-model="editInfo.info"
            >
            </el-input>
          </div>
          <!-- 方案封面图 -->
          <div>
            <div class="plan_title">方案封面图 |</div>
            <div class="editImg">
              <div>
                <el-upload
                  action="https://img.centurysouth.cn/group1/upload"
                  :limit="1"
                  ref="my-upload"
                  :show-file-list="true"
                  :on-remove="handleRemove"
                  :on-exceed="handleExceed"
                  :on-success="handleSuccess"
                >
                  <el-button size="small" type="primary">点击上传</el-button>
                  <div slot="tip">建议上传1920*1080的图片</div>
                </el-upload>
              </div>
              <div class="editImg_cover">
                <img :src="editInfo.cover" />
              </div>
            </div>
          </div>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false"> 取 消 </el-button>
          <el-button type="primary" @click="submitInfo()"> 确 定 </el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import qs from "qs";
export default {
  props: ["tabList", "count"],
  data() {
    return {
      dialogFormVisible: false,
      reviewList: [
        {
          name: "已通过",
          num: 0,
        },
        {
          name: "审核中",
          num: 0,
        },
        {
          name: "未通过",
          num: 0,
        },
        {
          name: "全部",
          num: 0,
        },
      ],
      titleInput: "",
      typeList: [
        {
          title: "全部",
          num: 0,
        },
        {
          title: "平面设计",
          num: 0,
        },
        {
          title: "三维设计",
          num: 0,
        },
        {
          title: "策划方案",
          num: 0,
        },
        {
          title: "视频作品",
          num: 0,
        },
      ],
      typeStatus: 0,
      // 编辑分类
      editSort: [],
      // 编辑信息
      editInfo: {},
    };
  },
  watch: {
    // 清除已上传列表中的数据
    dialogFormVisible(val) {
      if (!val) {
        this.$refs["my-upload"].clearFiles();
      }
    },
  },
  methods: {
    clearInput() {
      this.titleInput = "";
    },
    selectTab(index) {
      this.typeStatus = index;
    },
    // 打开详情
    openDetails(e) {
      this.$router.push({
        path: "/HomeDetails",
        query: {
          id: e,
        },
      });
    },
    // 分页切换
    currentChange(e) {
      this.$emit("pagingSwitch", e);
    },
    // 编辑方案
    openEdit(item) {
      console.log(item);
      this.$axios({
        url: this.$api + "/auth/ppt/cat/list",
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("user_token"),
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8;",
        },
      }).then((res) => {
        if (res.data.code == 200) {
          // 编辑信息
          let info = {
            item_id: item.id,
            title: item.title,
            cat_id: item.cat_id,
            cover: item.cover,
            info: item.info,
          };
          this.img_url = item.cover;
          this.editInfo = info;
          // 分类信息
          let sortList = res.data.data;
          sortList.map((item, key) => {
            item.children.map((list, index) => {
              // 因上传的多个分类选择后端方面还没有解决，所以先暂时这样写
              if (info.cat_id == list.id) {
                list.status = true;
              } else {
                list.status = false;
              }
            });
          });
          console.log(sortList);
          this.editSort = sortList;
          this.dialogFormVisible = true;
        }
      });
    },
    // 分类选择
    editSortSelect(index, key) {
      this.editSort[index].children[key].status = !this.editSort[index]
        .children[key].status;
    },
    // 删除我的方案
    delMyPlan(id) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$axios({
            url: this.$api + "/auth/ppt/details/edit",
            method: "DELETE",
            headers: {
              Authorization: localStorage.getItem("user_token"),
              "Content-Type":
                "application/x-www-form-urlencoded;charset=UTF-8;",
            },
            data: {
              item_id: id,
            },
          }).then((res) => {
            if (res.data.code == 200) {
              this.$message({
                showClose: true,
                message: res.data.msg,
                type: "success",
              });
              this.$emit("pagingSwitch", 1);
            } else {
              this.$message({
                showClose: true,
                message: res.data.msg,
                type: "warning",
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 处理超出个数的钩子
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 1 个文件`);
    },
    // 处理上传成功的钩子
    handleSuccess(response, file, fileList) {
      this.editInfo.cover = response;
    },
    // 处理文件列表移除的钩子
    handleRemove(file, fileList) {
      this.editInfo.cover = this.img_url;
    },
    // 提交数据
    submitInfo() {
      // 将被选中的分类id提取出来
      let info_cat_arr = [];
      this.editSort.map((item) => {
        item.children.map((list) => {
          if (list.status) {
            info_cat_arr.push(list.id);
          }
        });
      });
      // 将提取出来的id做拼接处理
      // let info_cat_str = "";
      // info_cat_arr.map((item, index) => {
      //   if (info_cat_arr.length == index + 1) {
      //     info_cat_str += item;
      //   } else {
      //     info_cat_str += item + ",";
      //   }
      // });
      // 这个处理是因为目前后端不支持多选所写的，如果后面改的话则需要重写。
      this.editInfo.cat_id = info_cat_arr[0];
      console.log(this.editInfo);
      if (this.editInfo.title == "") {
        this.$message({
          showClose: true,
          message: "方案标题不能为空",
          type: "warning",
        });
        return;
      }
      if (this.editInfo.cat_id == "") {
        this.$message({
          showClose: true,
          message: "请选择方案分类",
          type: "warning",
        });
        return;
      }
      if (this.editInfo.info == "") {
        this.$message({
          showClose: true,
          message: "方案简介不能为空",
          type: "warning",
        });
        return;
      }
      
      // 上传参数
      this.$axios({
        url: this.$api + "/auth/ppt/details/edit",
        method: "POST",
        headers: {
          Authorization: localStorage.getItem("user_token"),
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8;",
        },
        data: this.editInfo,
      }).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            showClose: true,
            message: res.data.msg,
            type: "success",
          });
          this.dialogFormVisible = false;
          this.$emit("pagingSwitch", 1);
        } else {
          this.$message({
            showClose: true,
            message: res.data.msg,
            type: "error",
          });
        }
      });
    },
  },
};
</script>

<style scoped>
/* 审核 */
.my-works-review {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #e5e5e5;
  height: 50px;
  padding: 10px 20px;
}
.my-works-review > div {
  width: 100%;
}
.my-works-reviewLeft {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  font-size: 16px;
  cursor: pointer;
  height: 100%;
}
.my-works-reviewLeft > div {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.my-works-reviewRight {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.my-works-reviewRight > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 30px;
  background-color: #fff;
  height: 35px;
  width: 75%;
}
.my-works-reviewRight > div > div {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.my-works-reviewRight > div > div:nth-of-type(1) {
  padding: 0 10px;
  /* width: 12%; */
}
.my-works-reviewRight > div > div:nth-of-type(2) {
  width: 60%;
}
.my-works-reviewRight > div > div:nth-of-type(3) {
  color: #a1a1a1;
  margin: 0 5px 0 0;
}
.my-works-reviewRight > div > div:nth-of-type(4) {
  padding: 0 20px;
  color: #fff;
  background-color: #949494;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}
.my-works-reviewRight > div > div:nth-of-type(4):active {
  background-color: #949494be;
}
.my-works-reviewRight input {
  outline: none;
  border: none;
  height: 100%;
  font-size: 14px;
}

/* 类型 */
.my-works-type {
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
}
.my-works-typeLeft {
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.my-works-typeLeft > div {
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.my-works-typeLeft_item {
  cursor: pointer;
  background-color: #e5e5e5;
  text-align: center;
  border-radius: 3px;
}
.my-works-typeLeft_item > div {
  margin: 0 5px;
}
.my-works-typeRight {
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.my-works-typeRight > div {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
  cursor: pointer;
}
.my-works-typeRight > div:nth-of-type(1) > div {
  margin: 0 5px;
}

/* 卡片内容列表 */
.card_list {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.card_list_item {
  width: 29%;
  font-size: 14px;
  border-radius: 10px;
  margin: 1% 2%;
  box-sizing: border-box;
  background-color: #fff;
  transition: box-shadow 0.5s;
}
.card_list_item:hover {
  box-shadow: 7px 7px 7px #e3e3e3, -7px -7px 7px #f4f4f4;
}
.card_list_itemImg {
  width: 100%;
  height: 20vh;
}
.card_list_itemImg > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s;
  border-radius: 10px;
}
.card_list_itemContent {
  cursor: pointer;
  box-sizing: border-box;
  margin: 15px;
  border-bottom: 1px solid #e3e3e3;
}
.card_list_itemContentText {
  box-sizing: border-box;
  padding: 20px 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.card_list_itemContentUser {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.card_list_itemContentUser > div {
  box-sizing: border-box;
  margin: 20px 10px;
}
.card_list_itemContentUser img {
  border-radius: 100%;
  width: 30px;
  height: 30px;
}
.card_list_itemData {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.card_list_itemData > div {
  display: flex;
  align-items: center;
  justify-content: center;
}
.card_list_itemData {
  padding: 20px;
  color: #666;
  box-sizing: border-box;
}
.card_list_itemData i {
  font-size: 20px;
  margin: 0 10px;
  box-sizing: border-box;
}
.card_list_itemContent_btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  overflow: hidden;
}
.card_list_itemContent_btn > div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.card_list_itemContent_btn .left_edit button {
  background-color: #409eff;
}
.card_list_itemContent_btn .right_delete button {
  background-color: #f56c6c;
}
.card_list_itemContent_btn button {
  background-color: #fff;
  outline: none;
  border: none;
  width: 100%;
  height: 40px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  color: #fff;
}

/* 编辑标题 */
.plan_title {
  font-size: 14px;
  font-weight: 600;
  margin: 10px 0;
  padding: 10px 0;
  border-bottom: 1px solid #e3e3e3;
}
/* 编辑封面图 */
.editImg {
  display: flex;
  justify-content: space-between;
}
.editImg_cover {
  width: 80%;
  height: 80%;
}
.editImg_cover > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
/* 编辑分类 */
.editSort {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.editSort_item {
  display: flex;
  /* align-items: center; */
  margin: 10px 0;
}
.editSort_items{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.editSort_item_content {
  padding: 5px 10px;
  margin: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}
.edit_title{
  min-width: 10%;
  padding: 5px 0;
}
</style>