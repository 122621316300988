<template>
  <div>
    <el-tabs v-model="activeName">
      <el-tab-pane label="单个上传" name="second">
        <div class="uploadPlan">
          <!-- 上传文件处 -->
          <div class="uploadPlan_show">
            <div class="uploadPlan_showTitle">
              <span>*</span>
              上传PPT源文件({{ uploadPlanNum }}/1)
            </div>
            <div class="uploadPlan_showInfo">
              <el-upload
                accept=".pdf,.ppt,.pptx,.doc,.xls"
                :limit="1"
                action="https://img.centurysouth.cn/group1/upload"
                multiple
                :on-success="handleSuccess"
                :on-exceed="handleLimit"
                :on-remove="handleRemove">
                <el-button type="success">选择源文件</el-button>
              </el-upload>
              <div>仅支持上传1个文件</div>
            </div>
          </div>
          <!-- 设置基本信息 -->
          <div class="installInfo">
            <div class="installInfo_title">设置基本信息</div>
            <!-- 活动类型 -->
            <div class="installInfo_box">
              <div class="installInfo_boxSort">
                <div class="installInfo_boxSortTitle">
                  <span>*</span>
                  活动类型：
                </div>
                <div class="installInfo_boxSortContent">
                  <div class="installInfo_boxSortContent_top">
                    <div class="supplement_sort">
                      <div v-for="(item, index) in selectSortList" :key="index">
                        <div class="supplement_sortItem">
                          <div>
                            {{ item.name }}
                          </div>
                          <i
                            @click="delSortList(index)"
                            class="el-icon-error"
                          ></i>
                        </div>
                      </div>
                      <div v-if="selectSortList.length < 2">请选择</div>
                    </div>
                    <div>最多选择2个</div>
                  </div>
                  <div class="installInfo_boxSortContent_main">
                    <div class="installInfo_boxSortContent_main_top">
                      <div
                        class="installInfo_boxSortContent_mainHeader"
                        v-for="(item, index) in sortList"
                        :key="index"
                      >
                        <div
                          @mouseover="selectSort(item.children, index)"
                          :style="
                            sortState == index
                              ? 'border-bottom: 3px solid #ff8b3d;'
                              : 'border-bottom: 3px solid #fff;'
                          "
                        >
                          {{ item.title }}
                        </div>
                      </div>
                    </div>
                    <div class="installInfo_boxSortContent_main_content">
                      <div
                        class="installInfo_boxSortContent_mainInfo"
                        v-for="(item, index) in sortContent"
                        :key="index"
                        @click="selectTab(item.id, item.title)"
                      >
                        <div>{{ item.title }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 关联验证码输入框 -->
          <div class="uploadPlanTitle">
            <div class="uploadPlanTitle_subtitle">关联项目验证码</div>
            <div class="uploadPlanTitle_input">
              <input
                v-model="projectVerCode"
                type="text"
                placeholder="如需关联项目，请输入关联项目验证码"
              />
              <i
                v-if="projectVerCode"
                class="el-icon-error"
                @click="clearprojectVerCode()"
              ></i>
            </div>
          </div>
          <!-- 自定义标题 -->
          <div class="uploadPlanTitle">
            <div class="uploadPlanTitle_subtitle">作品标题</div>
            <div class="uploadPlanTitle_input">
              <input
                v-model="projectTitle"
                type="text"
                placeholder="点击输入方案作品标题"
              />
              <i
                v-if="projectTitle"
                class="el-icon-error"
                @click="clearProjectTitle()"
              ></i>
            </div>
          </div>
          <!-- 自定义简介 -->
          <div>
            <div class="uploadPlanTitle_subtitle">作品介绍</div>
            <el-input
              type="textarea"
              :rows="6"
              placeholder="请输入内容"
              v-model="introduction"
              style="width: 50%; font-size: 16px"
            >
            </el-input>
          </div>
          <!-- 提交按钮 -->
          <div class="submit_btn">
            <button @click="submitData()" :disabled="disabledStatus">
              <i
                class="el-icon-loading"
                style="color: #fff"
                v-if="submitStatus"
              ></i>
              提交
            </button>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="多个上传" name="first">
        <div class="uploadPlan">
          <div class="uploadPlan_main">
            <div class="uploadPlan_main_title">
              <div>上传方案列表</div>
              <div>TIP: 请记得填写所有方案的标题和简介</div>
            </div>
            <div class="uploadPlan_main_box">
              <el-collapse>
                <el-collapse-item
                  :title="item.name"
                  :name="index"
                  v-for="(item, index) in pptList"
                  :key="index"
                >
                  <div class="uploadPlan_main_boxForm">
                    <div class="uploadPlan_main_boxFormTitle">
                      <div class="title">方案标题</div>
                      <div>
                        <el-input placeholder="请输入内容" v-model="item.title">
                          <template slot="prepend">标题</template>
                        </el-input>
                      </div>
                    </div>
                    <div class="uploadPlan_main_boxFormSummary">
                      <div class="title">方案简介</div>
                      <div>
                        <el-input
                          type="textarea"
                          :autosize="{ minRows: 4, maxRows: 4 }"
                          placeholder="请输入内容"
                          v-model="item.info"
                        ></el-input>
                      </div>
                    </div>
                    <div class="uploadPlan_main_boxFormSort">
                      <div class="title">方案分类</div>
                      <div class="sort">
                        <div class="sort_model">
                          <div
                            class="sort_item"
                            v-for="(sort, sortIndex) in item.sortLists"
                            :key="sortIndex"
                          >
                            <div class="sort_item_title">
                              {{ sort.title }}：
                            </div>
                            <div class="sort_items">
                              <div
                                class="sort_itemSubclass"
                                v-for="(list, listIndex) in sort.children"
                                :key="listIndex"
                              >
                                <div
                                  :style="
                                    list.status
                                      ? 'background-color: #fdd30f;'
                                      : ''
                                  "
                                  @click="
                                    passID(list.id, index, sortIndex, listIndex)
                                  "
                                >
                                  {{ list.title }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <el-button
                        @click="delppt(index)"
                        type="danger"
                        icon="el-icon-delete"
                        circle
                      ></el-button>
                    </div>
                  </div>
                </el-collapse-item>
              </el-collapse>
            </div>
          </div>
          <div class="uploadPlan_footer">
            <div class="uploadPlan_footer_upload">
              <el-upload
                accept=".pdf,application/pdf,.ppt,application/vnd.ms-powerpoint,.pptx"
                action="https://img.centurysouth.cn/group1/upload"
                multiple
                :limit="10"
                :show-file-list="true"
                :on-exceed="handleLimits"
                :on-progress="handleProgressArr"
                :on-success="handleSuccessArr"
              >
                <el-button size="small" type="primary"> 点击上传 </el-button>
                <div slot="tip" class="el-upload__tip">
                  一次最多只能上传10个文件
                </div>
              </el-upload>
            </div>
            <div class="uploadPlan_footer_submit">
              <el-button
                type="danger"
                @click="submitWorks"
                :loading="submitWorksState"
                :disabled="btnState"
              >
                提交
              </el-button>
            </div>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeName: "second",
      pptList: [],
      submitWorksState: false,
      btnState: true,
      // 上传文件数
      uploadNum: 0,
      // 上传成功文件数
      uploadSuccessNum: 0,
      // 提交成功文件数
      submitNum: 0,
      // 多选分类数组
      sortListArr: [],
      // 分类头
      sortState: 0,
      // 分类数据
      sortList: null,
      // 分类内容
      sortContent: [],
      // 项目验证码
      projectVerCode: "",
      // 方案标题
      projectTitle: "",
      // 上传方案数目
      uploadPlanNum: 0,
      // 上传方案路径
      uploadUrl: "",
      // 上传方案大小
      uploadSize: "",
      // 方案简介
      introduction: "",
      // 被选中分类id合集
      selectSortList: [],
      // 禁用状态
      disabledStatus: true,
      // 提交状态
      submitStatus: false,
    };
  },
  created() {
    this.getSort();
  },
  methods: {
    // 选择方案分类
    selectSort(x, f) {
      this.sortState = f;
      this.sortContent = x;
    },
    // 初始化分类
    getSort() {
      this.$axios({
        url: this.$api + "/auth/ppt/cat/list",
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("user_token"),
          "Content-Type": "application/json;charset=UTF-8;",
        },
      }).then((res) => {
        if (res.data.code == 200) {
          let sortList = res.data.data;
          this.sortList = sortList;
          sortList.map((item, index) => {
            item.children.map((cat, catindex) => {
              cat.status = false;
            });
          });
          this.sortListArr = sortList;
          this.sortContent = this.sortList[this.sortState].children;
        }
      });
    },
    // 清除项目验证码输入框
    clearprojectVerCode() {
      this.projectVerCode = "";
    },
    // 清除标题输入框
    clearProjectTitle() {
      this.projectTitle = "";
    },
    // 上传成功的钩子
    handleSuccess(response, file) {
      this.uploadPlanNum += 1;
      this.uploadUrl = response;
      this.uploadSize = file.size;
      this.disabledStatus = false;
    },
    // 处理文件数量超出限制的钩子
    handleLimit() {
      this.$message({
        showClose: true,
        message: "仅支持上传1个文件",
        type: "error",
      });
    },
    // 处理文件数量超出限制的钩子
    handleLimits() {
      this.$message({
        showClose: true,
        message: "仅支持一次性上传10个文件",
        type: "error",
      });
    },
    // 删除上传文件时的钩子
    handleRemove() {
      this.uploadPlanNum = 0;
      this.uploadUrl = "";
    },
    // 选中分类
    selectTab(id, name) {
      // 分类总数不能大于2
      if (this.selectSortList.length >= 2) {
        return;
      }
      let that = this;
      new Promise(function (resolve, reject) {
        // 选中分类不能重复
        let state = true;
        that.selectSortList.map((item, index) => {
          if (item.id == id) {
            state = false;
          }
        });
        resolve(state);
      }).then((res) => {
        if (res) {
          that.selectSortList.push({
            id: id,
            name: name,
          });
        }
      });
    },
    // 删除已选中分类
    delSortList(index) {
      this.selectSortList.splice(index, 1);
    },
    // 提交
    submitData() {
      if (!this.projectTitle) {
        this.$message({
          showClose: true,
          message: "请填写方案作品的标题",
          type: "warning",
        });
        return;
      }
      if (!this.introduction) {
        this.$message({
          showClose: true,
          message: "请填写方案作品的简介",
          type: "warning",
        });
        return;
      }
      if (!this.uploadUrl) {
        this.$message({
          showClose: true,
          message: "请上传需要发布的文件",
          type: "warning",
        });
        return;
      }
      if (this.selectSortList.length == 0) {
        this.$message({
          showClose: true,
          message: "请选择方案作品相对应的分类",
          type: "warning",
        });
        return;
      }
      this.submitStatus = true;
      this.disabledStatus = true;
      if (this.uploadSize > 30 * 1024 * 1024) {
        this.$notify({
          title: "注意",
          message: "提交的文件过大，服务器响应需要时间，请耐心等待",
          type: "warning",
        });
      }
      this.$axios({
        url: this.$api + "/auth/ppt/upload",
        method: "POST",
        headers: {
          Authorization: localStorage.getItem("user_token"),
          "Content-Type": "application/json;charset=UTF-8;",
        },
        data: {
          title: this.projectTitle,
          code: this.projectVerCode,
          info: this.introduction,
          down_url: this.uploadUrl,
          file_size: this.uploadSize,
          cat_id: this.selectSortList[0].id,
        },
      }).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            showClose: true,
            message: "提交成功",
            type: "success",
          });
          this.submitStatus = false;
          this.$router.push({
            path: "/home",
          });
        }
      });
    },
    // 多个上传正在进行时
    handleProgressArr(event, file, fileList) {
      this.uploadNum = fileList.length;
    },
    // 多个上传成功的钩子
    handleSuccessArr(file, fileList) {
      // 当所有文件上传完毕后，将更新按钮的状态
      this.uploadSuccessNum += 1;
      if (this.uploadSuccessNum == this.uploadNum) {
        this.btnState = false;
      }
      // 因为直接引用的话是引用内存地址，并不是新开一个内存，所以需要使用深拷贝的方法来解决这个问题
      let sortListArr = JSON.parse(JSON.stringify(this.sortListArr));
      this.pptList.push({
        down_url: file,
        name: fileList.name,
        title: "",
        info: "",
        file_size: fileList.size,
        cat_id: "",
        cat_id_list: [],
        sortLists: sortListArr,
        successStatus: true,
      });
    },
    // 存储选择的分类id
    passID(id, index, sortIndex, listIndex) {
      // 先将传进来的id处理好
      if (this.pptList[index].cat_id_list.length == 0) {
        this.pptList[index].cat_id_list.push(id);
        this.pptList[index].sortLists[sortIndex].children[
          listIndex
        ].status = true;
      } else {
        let judge = this.pptList[index].cat_id_list.indexOf(id);
        if (judge == -1) {
          this.pptList[index].cat_id_list.push(id);
          this.pptList[index].sortLists[sortIndex].children[
            listIndex
          ].status = true;
        } else {
          this.pptList[index].cat_id_list.splice(judge, 1);
          this.pptList[index].sortLists[sortIndex].children[
            listIndex
          ].status = false;
        }
      }
      // 在将处理好的id进行赋值
      // let cat_id = "";
      // this.pptList[index].cat_id_list.forEach((item, catIndex) => {
      //   if (this.pptList[index].cat_id_list.length == catIndex + 1) {
      //     cat_id += item;
      //   } else {
      //     cat_id += item + ",";
      //   }
      // });
      // this.pptList[index].cat_id = cat_id;
      this.pptList[index].cat_id = this.pptList[index].cat_id_list[0];
      // vue2.x 存在如果数组对象层级过深导致监听不到位的问题，
      // 因此尽管其中有的属性已经被函数改变，但也只是数据层发生改变，
      // 视图层并没出现变化，这时候就需要以下函数进行强制更新
      this.$forceUpdate();
    },
    // 提交方案
    submitWorks() {
      this.submitWorksState = true;
      this.$notify({
        title: "注意",
        message: "提交的文件过大，服务器响应需要时间，请耐心等待",
        type: "warning",
      });
      this.pptList.map((item, key) => {
        if (item.title == "") {
          this.$message({
            showClose: true,
            message: "方案" + item.name + "标题不能为空",
            type: "warning",
          });
          this.submitWorksState = false;
          return;
        }
        if (item.info == "") {
          this.$message({
            showClose: true,
            message: "方案" + item.name + "简介不能为空",
            type: "warning",
          });
          this.submitWorksState = false;
          return;
        }
        if (item.cat_id == "") {
          this.$message({
            showClose: true,
            message: "方案" + item.name + "分类不能为空",
            type: "warning",
          });
          this.submitWorksState = false;
          return;
        }

        if (item.successStatus) {
          this.$axios({
            url: this.$api + "/auth/ppt/upload",
            method: "POST",
            headers: {
              Authorization: localStorage.getItem("user_token"),
              "Content-Type": "application/json;charset=UTF-8;",
            },
            data: {
              title: item.title,
              info: item.info,
              down_url: item.down_url,
              file_size: item.file_size,
              cat_id: item.cat_id,
            },
          }).then((res) => {
            if (res.data.code == 200) {
              this.submitNum += 1;
              item.successStatus = false;
              if (this.submitNum == this.uploadNum) {
                this.submitWorksState = false;
                this.btnState = true;
                this.$message({
                  showClose: true,
                  message: "所有方案提交成功",
                  type: "success",
                });
                setTimeout(() => {
                  this.$router.push({
                    path: "/home",
                  });
                }, 1500);
              }
            } else {
              this.submitWorksState = false;
              this.$message({
                showClose: true,
                message: res.data.msg,
                type: "warning",
              });
            }
          });
        }
      });
    },
    // 删除上传的方案
    delppt(index) {
      this.pptList.splice(index, 1);
    },
  },
};
</script>

<style scoped>
.title {
  font-size: 16px;
  font-weight: 600;
  margin: 10px 0;
}
.uploadPlan {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.uploadPlan_main_title > div:nth-of-type(2) {
  font-size: 10px;
  color: #666;
}
.sort_model {
  overflow: hidden;
  transition: height 0.5s;
}
.sort_item {
  font-weight: 600;
  font-size: 14px;
  margin: 10px 0;
  display: flex;
  justify-content: flex-start;
}
.sort_item_title{
  cursor: default;
  min-width: 10%;
  margin: 10px 0;
}
.sort_items{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.sort_itemSubclass {
  font-weight: normal;
  cursor: pointer;
}
.sort_itemSubclass > div {
  text-align: center;
  border-radius: 5px;
  padding: 5px 10px;
  margin: 5px;
  user-select: none;
  box-sizing: border-box;
}
.uploadPlan_main {
  height: 100%;
}
.uploadPlan_main_box {
  margin-top: 20px;
}

.uploadPlan_footer {
  border-top: 1px solid #e3e3e3;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
}
/* 上传 */
.uploadPlan_show {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  border: 1px solid #e3e3e3;
}
.uploadPlan_showTitle {
  font-weight: 600;
  font-size: 18px;
  margin: 10px 0;
}
.uploadPlan_showTitle > span {
  color: red;
}
.uploadPlan_showInfo {
  width: 30%;
  height: 20vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 1px solid #e3e3e3;
  border-radius: 5px;
}
.uploadPlan_showInfo > div {
  font-size: 16px;
  margin-top: 20px;
  color: #949494;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.uploadPlan_show >>> .el-upload-list {
  width: 80%;
}
/* 设置基本信息 */
.installInfo {
  font-size: 18px;
  margin-top: 20px;
}
.installInfo_box {
  width: 100%;
  margin: 20px 0;
  padding: 20px;
  border: 1px solid #e3e3e3;
  box-sizing: border-box;
  font-size: 16px;
}
.installInfo_boxSort {
  display: flex;
  justify-content: flex-start;
}
.installInfo_boxSortTitle {
  min-width: 10%;
}
.installInfo_boxSortTitle > span {
  color: red;
}
.installInfo_boxSortContent_top {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
}
.installInfo_boxSortContent_top > div:nth-of-type(2) {
  color: #ff8b3d;
  margin: 0 10px;
}
.installInfo_boxSortContent_main {
  margin: 20px 0;
}
.installInfo_boxSortContent_main_top {
  display: flex;
  align-items: center;
}
.installInfo_boxSortContent_main_content {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.installInfo_boxSortContent_mainHeader {
  color: #666;
  margin: 5px;
  padding: 5px;
  width: 78px;
  font-size: 14px;
  box-sizing: border-box;
  cursor: default;
}
.installInfo_boxSortContent_mainHeader > div {
  padding: 5px;
}
.installInfo_boxSortContent_mainInfo {
  display: flex;
  align-items: center;
  justify-content: center;
}
.installInfo_boxSortContent_mainInfo > div {
  background-color: #e3e3e3;
  font-size: 14px;
  border-radius: 5px;
  padding: 5px 10px;
  margin: 10px;
  cursor: pointer;
}
.installInfo_boxSortContent_mainInfo > div:hover {
  background-color: #ff8b3d;
  color: #fff;
}
/* 自定义标题 */
.uploadPlanTitle {
  margin: 20px 0;
}
.uploadPlanTitle_subtitle {
  font-size: 18px;
  margin: 10px 0;
}
.uploadPlanTitle_input {
  background-color: #dddddd;
  width: 25%;
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
}
.uploadPlanTitle_input > input {
  outline: none;
  padding: 10px;
  width: 90%;
  font-size: 14px;
  box-sizing: border-box;
  border: 1px solid #dddddd;
  background-color: #dddddd;
}
.uploadPlanTitle_input > i {
  width: 10%;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #949494;
}
/* 提交按钮 */
.submit_btn {
  margin: 30px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.submit_btn > button {
  width: 10vw;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 18px;
  padding: 10px 20px;
  border-radius: 5px;
  box-sizing: border-box;
  background-color: #fdd30f;
  display: flex;
  align-items: center;
  justify-content: center;
}
.submit_btn > button:active {
  background-color: #e6be10;
}
/* 补充样式 */
.supplement_sort {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.supplement_sortItem {
  border-radius: 5px;
  background-color: #fdd30f;
  padding: 5px;
  margin: 0 5px;
  font-size: 14px;
  cursor: default;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}
.supplement_sortItem i {
  color: #474747;
  margin: 5px;
  cursor: pointer;
}
</style>