<template>
  <div class="my-topics">
    <!-- 搜索框 -->
    <div class="my-topics-sort">
      <div class="my-topics-sortRight">
        <!-- <div>
          <div>标题</div>
          <div>
            <input type="text" v-model="titleInput" placeholder="请输入关键字进行搜索" />
          </div>
          <div v-show="titleInput" @click="clearTitleInput()">
            <i class="el-icon-error"></i>
          </div>
          <div>
            <i class="el-icon-search"></i>
          </div>
        </div> -->
        <div>
          <el-button
            type="info"
            icon="el-icon-circle-plus-outline"
            circle
            @click="openAddTopics()"
          ></el-button>
        </div>
      </div>
    </div>
    <!-- 内容 -->
    <div class="my-topics-content" @scroll.passive="getScroll($event)">
      <div
        v-for="(item, index) in tabList"
        :key="index"
        class="my-topics-contentItem"
        @click="openTopic(item.id)"
      >
        <div class="my-topics-box">
          <div class="my-topics-boxItem">{{ item.title }}</div>
          <div class="my-topics-boxBtn">
            <div @click.stop="editSpecial(item.id)">编辑</div>
            <div @click.stop="delSpecial(item.id)">删除</div>
          </div>
        </div>
        <img class="my-topics-contentImg" :src="item.cover" />
      </div>
    </div>
    <div style="display: flex; align-items: center; justify-content: center">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="count"
        :page-size="10"
        @current-change="currentChange"
      >
      </el-pagination>
    </div>
    <!-- 新增专题弹窗 -->
    <el-dialog
      :title="dialogTitle"
      width="1500px"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div class="newTopic">
        <!-- 左侧方案填写 -->
        <div class="newTopicLeft">
          <!-- 标题 -->
          <div class="newTopicLeft_title">
            <div class="title">标题</div>
            <el-input placeholder="请输入内容" v-model="topic.title" clearable>
              <template slot="prepend">标题</template>
            </el-input>
          </div>
          <!-- 简介 -->
          <div class="newTopicLeft_outline">
            <div class="title">简介</div>
            <el-input
              v-model="topic.info"
              type="textarea"
              :autosize="{ minRows: 4, maxRows: 4 }"
              placeholder="请输入内容"
            ></el-input>
          </div>
          <!-- 专题图 -->
          <div class="newTopicLeft_img">
            <div class="newTopicLeft_img_title">
              <div>专题主图</div>
              <div>tip: 最好请上传1000 * 500尺寸的图片</div>
            </div>
            <div class="newTopicLeft_img_main">
              <!-- 上传 -->
              <div class="newTopicLeft_imgUpload">
                <el-upload
                  action="https://img.centurysouth.cn/group1/upload"
                  :on-success="main_img"
                >
                  <el-button size="small" type="primary">点击上传</el-button>
                </el-upload>
              </div>
              <!-- 上传后的图片 -->
              <div class="newTopicLeft_mainImg">
                <img :src="topic.cover" />
              </div>
            </div>
          </div>
          <!-- 主题方案 -->
          <div class="newTopicLeft_plan">
            <div class="title">收集的方案</div>
            <div
              class="newTopicLeft_plan_main"
              :style="planList.length ? 'border: 1px solid #666;' : ''"
            >
              <div v-for="(item, index) in planList" :key="index">
                <div class="plan_main_del">
                  <i @click="delPlan(index, item)" class="el-icon-close"></i>
                </div>
                <div class="plan_main_img">
                  <img :src="item.cover" />
                </div>
                <div class="plan_main_title">{{ item.title }}</div>
              </div>
            </div>
          </div>
        </div>
        <!-- 右侧资源搜索 -->
        <div class="newTopicRight">
          <!-- 搜索方案 -->
          <div class="newTopicRightSearch">
            <div class="title">搜索</div>
            <el-input
              placeholder="请输入内容"
              v-model="searchInput"
              clearable
              @keyup.enter.native="searchList()"
              @clear="clearInput"
            >
              <el-select
                v-model="selectItem"
                slot="prepend"
                placeholder="请选择"
              >
                <el-option label="方案" value="1"></el-option>
                <el-option label="作者" value="2"></el-option>
              </el-select>
              <el-button
                @click="searchList()"
                slot="append"
                icon="el-icon-search"
              ></el-button>
            </el-input>
          </div>
          <!-- 分类操作栏 -->
          <div class="sort">
            <div class="sort_icon">
              <div @click="unfold">
                <i
                  class="el-icon-arrow-right"
                  :style="
                    sortStatus
                      ? 'transform: rotate(90deg);transition: transform .5s;'
                      : 'transition: transform .5s;'
                  "
                ></i>
                <div class="title">方案分类</div>
              </div>
            </div>
            <div
              class="sort_model"
              :style="sortStatus ? 'height: 25vh;' : 'height: 0vh;'"
            >
              <div
                class="sort_item"
                v-for="(item, index) in sortList"
                :key="index"
              >
                <div style="cursor: default">{{ item.title }}：</div>
                <div>
                  <div
                    class="sort_itemSubclass"
                    v-for="(list, listIndex) in item.children"
                    :key="listIndex"
                  >
                    <div
                      :style="
                        listParameter.cat_id == list.id
                          ? 'background-color: #F1DC64;'
                          : ''
                      "
                      @click="passID(list.id)"
                    >
                      {{ list.title }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 方案展示 -->
          <div class="newTopicRightPlan">
            <div class="title">方案</div>
            <div class="newTopicRightPlan_box">
              <div v-for="(item, index) in list" :key="index">
                <div class="newTopicRightPlan_content">
                  <!-- 方案图 -->
                  <div class="newTopicRightPlan_content_img">
                    <img :src="item.cover" />
                  </div>
                  <!-- 方案内容 #03a9f41f -->
                  <div
                    :class="
                      item.state
                        ? 'newTopicRightPlan_content_info newTopicRightPlan_content_infoOne'
                        : 'newTopicRightPlan_content_info'
                    "
                  >
                    <!-- 方案名称 -->
                    <div>{{ item.title }}</div>
                    <!-- 作者信息 -->
                    <div>
                      <div class="newTopicRightPlan_content_infoPrc">
                        <img :src="item.picture" />
                      </div>
                      <div>{{ item.username }}</div>
                    </div>
                  </div>
                </div>
                <!-- 方案操作 -->
                <div
                  class="newTopicRightPlan_operating"
                  @click="
                    addPlan({
                      id: item.id,
                      cover: item.cover,
                      title: item.title,
                    })
                  "
                >
                  <div>
                    <i class="el-icon-circle-plus"></i>
                  </div>
                </div>
              </div>
            </div>
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: center;
              "
            >
              <el-pagination
                small
                layout="prev, pager, next"
                :total="counts"
                :page-size="20"
                :current-page="listParameter.page"
                @current-change="dialogCurrentChange"
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
      <div slot="footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="dialogTitle == '新增专题' ? addTopic() : updataSpecial()"
        >
          确 定
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: ["tabList", "count"],
  data() {
    return {
      // 分类展开收起状态
      sortStatus: false,
      // 分类列表
      sortList: null,
      // 列表数组
      list: [],
      // 总数
      counts: "",
      // 发送的参数
      listParameter: {
        page: 1,
        title: "",
        username: "",
        cat_id: "",
        hot: "",
        new: "",
      },
      // 搜索框
      titleInput: "",
      // 主题提交参数
      topic: {
        title: "",
        info: "",
        cover:
          "https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg",
        item_ids: "",
      },
      // 右侧搜索
      searchInput: "",
      selectItem: "",
      dialogFormVisible: false,
      dialogTitle: "",
      // 方案列表
      planList: [],
    };
  },
  watch: {
    searchInput(val) {
      if (val == "") {
        this.clearInput();
      }
    },
  },
  created() {
    this.getSort();
    this.getList();
  },
  methods: {
    // 展开收起分类
    unfold() {
      this.sortStatus = !this.sortStatus;
    },
    // 分类id保存
    passID(id) {
      if (this.listParameter.cat_id == id) {
        this.listParameter.cat_id = "";
      } else {
        this.listParameter.cat_id = id;
      }
      this.getList();
    },
    // 初始化列表
    getList() {
      let listParameter = this.listParameter;
      this.$axios({
        url: this.$api + "/auth/ppt/indexlist",
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("user_token"),
          "Content-Type": "application/json;charset=UTF-8;",
        },
        params: listParameter,
      }).then((res) => {
        let list = res.data.data;
        list.res.map((item) => {
          item.state = false;
        });
        this.list = list.res;
        this.counts = list.count;
      });
    },
    // 清除搜索框
    clearTitleInput() {
      this.titleInput = "";
    },
    // 打开专题详情
    openTopic(e) {
      this.$router.push({
        path: "/topicDetails",
        query: {
          id: e,
        },
      });
    },
    // 上传成功
    main_img(res, file) {
      this.topic.cover = res;
    },
    // 初始化分类
    getSort() {
      this.$axios({
        url: this.$api + "/auth/ppt/cat/list",
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("user_token"),
          "Content-Type": "application/json;charset=UTF-8;",
        },
      }).then((res) => {
        this.sortList = res.data.data;
      });
    },
    // 搜索列表
    searchList() {
      this.listParameter.page = 1;
      if (this.selectItem == 1) {
        this.listParameter.title = this.searchInput;
      } else if (this.selectItem == 2) {
        this.listParameter.username = this.searchInput;
      } else {
        this.$message({
          showClose: true,
          message: "请选择方案或者作者",
          type: "warning",
        });
        return;
      }
      this.getList();
    },
    // 清除输入框内容后执行
    clearInput() {
      this.listParameter = {};
      this.getList();
    },
    // 添加方案
    addPlan(e) {
      // 检测是否重复添加
      let fil = this.planList.find(function (item) {
        return item.id == e.id;
      });
      if (!fil) {
        this.planList.push(e);
        this.selectedState(e.id);
      } else {
        this.$message({
          showClose: true,
          message: "请勿重复添加",
          type: "warning",
        });
      }
    },
    // 删除方案
    delPlan(e, f) {
      this.planList.splice(e, 1);
      this.selectedState(f.id);
    },
    // 选中状态
    selectedState(e) {
      this.list.map((item, index) => {
        if (item.id == e) {
          item.state = !item.state;
        }
      });
    },
    // 开启新增专题
    openAddTopics() {
      this.dialogTitle = "新增专题";
      this.dialogFormVisible = true;
      this.topic = {
        title: "",
        info: "",
        cover:
          "https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg",
        item_ids: "",
      };
      this.planList = [];
    },
    // 新增专题
    addTopic() {
      this.planList.map((item, index) => {
        if (this.planList.length == index + 1) {
          this.topic.item_ids += item.id;
        } else {
          this.topic.item_ids += item.id + ",";
        }
      });
      this.$axios({
        url: this.$api + "/auth/ppt/album/insert",
        method: "POST",
        headers: {
          Authorization: localStorage.getItem("user_token"),
          "Content-Type": "application/json;charset=UTF-8;",
        },
        data: this.topic,
      }).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            showClose: true,
            message: "专题新增成功",
            type: "success",
          });
          this.$emit("updateData");
          this.dialogFormVisible = false;
        } else {
          this.$message({
            showClose: true,
            message: res.data.msg,
            type: "warning",
          });
        }
      });
    },
    // 弹窗列表分页切换
    dialogCurrentChange(e) {
      this.listParameter.page = e;
      console.log("下一页");
      this.getList();
    },
    // 分页切换
    currentChange(e) {
      this.$emit("pagingSwitch", e);
    },
    // 开启编辑专题
    editSpecial(id) {
      this.$axios({
        url: this.$api + "/auth/ppt/album/deails",
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("user_token"),
          "Content-Type": "application/json;charset=UTF-8;",
        },
        params: {
          item_id: id,
        },
      }).then((res) => {
        if (res.data.code == 200) {
          let info = res.data.data.album;
          this.topic = {
            id: id,
            title: info.title,
            info: info.info,
            cover: info.cover,
            item_ids: "",
          };
          this.planList = res.data.data.list;
          this.dialogTitle = "编辑专题";
          this.dialogFormVisible = true;
        }
      });
    },
    // 确认修改专题
    updataSpecial() {
      this.planList.map((item, index) => {
        if (this.planList.length == index + 1) {
          this.topic.item_ids += item.id;
        } else {
          this.topic.item_ids += item.id + ",";
        }
      });
      this.$axios({
        url: this.$api + "/auth/ppt/albumdeal/dealtop",
        method: "PUT",
        headers: {
          Authorization: localStorage.getItem("user_token"),
          "Content-Type": "application/json;charset=UTF-8;",
        },
        data: this.topic,
      }).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            showClose: true,
            message: "修改成功",
            type: "success",
          });
          this.dialogFormVisible = false;
          this.$emit("updateData");
        }
      });
    },
    // 删除专题
    delSpecial(id) {
      this.$confirm("此操作将永久删除该专题, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$axios({
            url: this.$api + "/auth/ppt/albumdeal/dealtop",
            method: "DELETE",
            headers: {
              Authorization: localStorage.getItem("user_token"),
              "Content-Type": "application/json;charset=UTF-8;",
            },
            data: {
              id: id,
            },
          }).then((res) => {
            if (res.data.code == 200) {
              this.$message({
                showClose: true,
                message: "删除成功",
                type: "success",
              });
              this.$emit("updateData");
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
</script>

<style scoped>
.my-topics >>> .el-dialog {
  margin-top: 2vh !important;
}
/* 搜索 */
.my-topics-sort {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  height: 50px;
  padding: 10px 20px;
  background-color: #e5e5e5;
}
.my-topics-sortRight {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.my-topics-sortRight > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 30px;
  background-color: #fff;
  height: 35px;
}
/* .my-topics-sortRight > div:nth-of-type(1) {
  width: 35%;
} */
.my-topics-sortRight > div:nth-of-type(2) > button {
  font-size: 18px;
}
.my-topics-sortRight > div > div {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.my-topics-sortRight > div > div:nth-of-type(1) {
  padding: 0 10px;
}
.my-topics-sortRight > div > div:nth-of-type(2) {
  width: 60%;
}
.my-topics-sortRight > div > div:nth-of-type(3) {
  color: #a1a1a1;
  margin: 0 5px 0 0;
}
.my-topics-sortRight > div > div:nth-of-type(4) {
  padding: 0 20px;
  color: #fff;
  background-color: #949494;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}
.my-topics-sortRight > div > div:nth-of-type(4):active {
  background-color: #949494be;
}
.my-topics-sortRight input {
  outline: none;
  border: none;
  height: 100%;
  font-size: 14px;
}

/* 内容 */
.my-topics-content {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 10px;
}
.my-topics-content > div {
  width: 31%;
  height: 20vh;
  border-radius: 10px;
  overflow: hidden;
  margin: 10px;
  position: relative;
}
.my-topics-contentItem:hover .my-topics-contentImg {
  transform: scale(1.5);
}
.my-topics-contentItem:hover .my-topics-box {
  top: 0;
}
.my-topics-box {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
  z-index: 2;
  box-sizing: border-box;
  transition: top 0.5s;
  background-color: #ffffff49;
}
.my-topics-boxItem {
  padding: 5px;
  box-sizing: border-box;
  color: #e3e3e3;
  width: 100%;
  height: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.my-topics-boxBtn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.my-topics-boxBtn > div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 35px;
  color: #fff;
}
.my-topics-boxBtn > div:nth-of-type(1) {
  background-color: #40a0ffb2;
  letter-spacing: 3px;
}
.my-topics-boxBtn > div:nth-of-type(2) {
  background-color: #f56c6cb9;
  letter-spacing: 3px;
}
.my-topics-content img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s;
}

.title {
  font-size: 16px;
  font-weight: 600;
  margin: 10px 0;
}
.newTopic {
  display: flex;
  justify-content: space-between;
}
/* 左 */
.newTopicLeft {
  width: 60%;
  padding: 10px;
  box-sizing: border-box;
  border-right: 1px solid #e3e3e3;
}
.newTopicLeft > div {
  margin: 20px 0;
}
.newTopicLeft_img_title > div {
  margin: 10px 0;
}
.newTopicLeft_img_title > div:nth-of-type(1) {
  font-size: 16px;
  font-weight: 600;
}
.newTopicLeft_img_title > div:nth-of-type(2) {
  font-size: 10px;
}
.newTopicLeft_img_main {
  display: flex;
  justify-content: space-between;
}
.newTopicLeft_mainImg {
  width: 90%;
  height: 200px;
}
.newTopicLeft_mainImg > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.newTopicLeft_plan_main {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 10px;
  box-sizing: border-box;
}
.newTopicLeft_plan_main > div {
  margin: 5px 0;
  width: 20%;
  border: 1px solid #e3e3e3;
}
.newTopicLeft_plan_main > div > div {
  padding: 5px 0;
  box-sizing: border-box;
}
.plan_main_del {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.plan_main_img {
  /* width: 20%; */
  height: 80px;
}
.plan_main_img > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.plan_main_title {
  margin: 0 auto;
  font-size: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 50%;
}
/* 右 */
.newTopicRightSearch >>> .el-input-group__prepend {
  width: 20%;
}
.newTopicRight {
  width: 40%;
  padding: 10px;
  box-sizing: border-box;
}
.newTopicRight > div {
  margin: 20px 0;
}
.newTopicRightPlan_box {
  overflow-x: auto;
  height: 50vh;
}
.newTopicRightPlan_box::-webkit-scrollbar {
  display: none;
}
.newTopicRightPlan_box > div {
  width: 110%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transform: translateX(0);
  transition: transform 0.5s;
}
.newTopicRightPlan_box > div:hover {
  transform: translateX(-10%);
}
.newTopicRightPlan_operating {
  font-size: 28px;
  margin: 0 10px;
}
.newTopicRightPlan_content {
  width: 100%;
  margin: 10px 0;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  border: 1px solid #e3e3e3;
  box-shadow: 5px 5px 5px #e3e3e3;
}
.newTopicRightPlan_content_img {
  width: 70%;
  height: 150px;
}
.newTopicRightPlan_content_img > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.newTopicRightPlan_content_info {
  font-size: 14px;
  width: 30%;
  display: flex;
  padding: 10px;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}
.newTopicRightPlan_content_infoOne {
  background-color: #03a9f41f;
}
.newTopicRightPlan_content_info > div:nth-of-type(1) {
  font-weight: 600;
}
.newTopicRightPlan_content_info > div:nth-of-type(2) {
  border-top: 1px solid #e3e3e3;
  padding: 10px;
  box-sizing: border-box;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}
.newTopicRightPlan_content_infoPrc {
  width: 35px;
  height: 35px;
  padding: 5px;
}
.newTopicRightPlan_content_infoPrc > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 100%;
}

/* 分类操作栏 */
.sort {
  margin: 30px 0;
}
.sort_icon > div {
  padding: 10px 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.sort_icon_title {
  margin: 0 10px;
}
.sort_model {
  overflow: hidden;
  transition: height 0.5s;
}
.sort_item {
  font-weight: 600;
  font-size: 14px;
  margin: 10px 0;
  display: flex;
  justify-content: flex-start;
}
.sort_item > div:nth-child(1) {
  width: 15%;
  margin: 5px 0;
}
.sort_item > div:nth-child(2) {
  width: 85%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.sort_itemSubclass {
  font-weight: normal;
  margin: 0 10px;
}
.sort_itemSubclass > div {
  text-align: center;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  user-select: none;
}
</style>