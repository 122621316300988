<template>
  <div class="user">
    <div class="user_top">
      <div class="user_top_select">
        <div>
          <div>人气</div>
          <div>{{ my_nums.visit_num }}</div>
        </div>
        <div>
          <div>评论</div>
          <div>{{ my_nums.comm_num }}</div>
        </div>
        <div>
          <div>点赞</div>
          <div>{{ my_nums.like_num }}</div>
        </div>
        <div>
          <div>下载</div>
          <div>{{ my_nums.down_num }}</div>
        </div>
      </div>
    </div>
    <div class="user_main">
      <div class="user_main_user">
        <div class="user_main_userInfo">
          <div>
            <img :src="userPic" />
          </div>
          <div>{{ userName }}</div>
        </div>
      </div>
      <div class="user_main_tab">
        <div class="user_main_tabLeft">
          <div
            class="user_main_tabLeftItem"
            :style="selectIndex == index + 1 ? 'background-color: #ddd;' : ''"
            v-for="(item, index) in list"
            :key="index"
            @click="selectTab(index + 1)"
          >
            {{ item.title }}
          </div>
        </div>
        <div class="user_main_tabRight">
          <my-works
            :tabList="tabList"
            :my_nums="my_nums"
            :count="count"
            @pagingSwitch="pagingSwitch"
            v-if="selectIndex == 1"
          ></my-works>
          <upload-works v-else-if="selectIndex == 2"></upload-works>
          <my-favorites
            :tabList="tabList"
            :my_nums="my_nums"
            :count="count"
            @pagingSwitch="pagingSwitch"
            v-else-if="selectIndex == 3"
          ></my-favorites>
          <my-topic
            :tabList="tabList"
            :my_nums="my_nums"
            :count="count"
            @pagingSwitch="pagingSwitch"
            @updateData="updateData(4)"
            v-else-if="selectIndex == 4"
          ></my-topic>
          <my-collect v-else-if="selectIndex == 5"></my-collect>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import myFavorites from "./components/my-favorites.vue";
import myTopic from "./components/my-topic.vue";
import myWorks from "./components/my-works.vue";
import uploadWorks from "./components/upload-works.vue";
import myCollect from "./components/my-collect.vue";
export default {
  components: {
    myFavorites,
    myTopic,
    myWorks,
    uploadWorks,
    myCollect,
  },
  data() {
    return {
      list: [
        {
          title: "我的作品",
        },
        {
          title: "上传作品",
        },
        {
          title: "我的收藏",
        },
        {
          title: "我的专题",
        },
        {
          title: "我的素材",
        },
      ],
      tabList: [],
      my_nums: {},
      // 用户头像
      userPic: "",
      userName: "",
      // 选中状态
      selectIndex: 1,
      // 发送状态
      cat: 1,
      // 当前页面
      currentPage: 1,
      // 总条目数
      count: 0,
    };
  },
  created() {
    this.getUserInfo();
    this.getTabInfo();
    this.getInfo();
  },
  methods: {
    // 路由跳转
    getInfo() {
      let tabId = this.$route.query.tabId;
      if (tabId == 2) {
        this.selectIndex = "2";
      } else if (tabId == 5) {
        this.selectIndex = "5";
      }
      this.getTabInfo();
    },
    // 获取默认数据
    getTabInfo() {
      this.$axios({
        url: this.$api + "/auth/user/creation",
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("user_token"),
          "Content-Type": "application/json;charset=UTF-8;",
        },
        params: {
          page: this.currentPage,
          cat: this.cat,
        },
      }).then((res) => {
        if (res.data.code == 200) {
          let list = res.data.data;
          this.tabList = list.list;
          this.count = list.count;
          this.my_nums = list.my_nums;
        }
      });
    },
    // 获取个人信息
    getUserInfo() {
      this.$axios({
        url: this.$api + "/auth/user/info",
        mounted: "GET",
        headers: {
          Authorization: localStorage.getItem("user_token"),
          "Content-Type": "application/json;charset=UTF-8;",
        },
      }).then((res) => {
        this.userPic = res.data.data.pic;
        this.userName = res.data.data.username;
      });
    },
    // tap切换
    selectTab(e) {
      console.log(e);
      this.selectIndex = e;
      if(e == 1){
        this.cat = 1;
        this.getTabInfo();
      }else if(e == 3){
        this.cat = 2;
        this.getTabInfo();
      }else if(e == 4){
        this.cat = 3;
        this.getTabInfo();
      }
    },
    // 更新专题数据
    updateData(e){
      this.cat = e;
      this.getTabInfo();
    },
    // 分页切换
    pagingSwitch(val){
      this.currentPage = val;
      this.getTabInfo();
    }
  },
};
</script>
<style scoped>
.user_top {
  width: 100%;
  height: 30vh;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-image: url("http://img.centurysouth.cn/group1/default/20200929/11/28/0/user_section_top.jpg");
}
.user_top_select {
  font-size: 16px;
  color: #fff;
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
  height: 100%;
  width: 30%;
  margin: 0 auto;
  text-align: center;
}
.user_top_select > div {
  width: 100%;
  margin: 0 0 50px;
  box-sizing: border-box;
  cursor: pointer;
}
.user_top_select > div:hover {
  background-color: #00000077;
}
.user_top_select > div:nth-of-type(1){
  border-left: none;
}
.user_top_select > div {
  border-left: 2px solid #ffffff80;
}
.user_top_select > div > div {
  margin: 5px 0;
}

/* 主体 */
.user_main {
  padding: 90px 30px;
  width: 80%;
  margin: 0 auto;
  box-sizing: border-box;
  background-color: #fff;
  position: relative;
}
@media screen and (max-width: 1500px) {
  .user_main {
    width: 1200px;
  }
}
.user_main_user {
  display: flex;
  justify-content: space-between;
}
.user_main_userInfo {
  position: absolute;
  top: -4em;
  left: 4em;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.user_main_userInfo > div:nth-of-type(1) {
  border: 2px solid #949494;
  border-radius: 100%;
  overflow: hidden;
  width: 100px;
  height: 100px;
}
.user_main_userInfo > div:nth-of-type(2) {
  margin: 10px 0;
}
.user_main_userInfo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* 选项卡 */
.user_main_tab {
  display: flex;
  justify-content: space-between;
}
.user_main_tabLeft {
  border: 1px solid #c1c1c1;
  border-radius: 15px;
  width: 12%;
  height: 360px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
}
.user_main_tabLeftItem {
  font-size: 16px;
  cursor: pointer;
  padding: 10px;
  margin: 10px 0;
  border-bottom: 1px solid #c1c1c1;
}
.user_main_tabLeftItem:hover {
  background-color: #e3e3e3;
}
.user_main_tabRight {
  width: 85%;
}
@media screen and (min-width: 2000px) and (max-width: 2500px) {
  .user_main_tabLeft{
    height: 480px;
  }
  .user_main_tabLeftItem {
    font-size: 24px;
  }
  .user_main_userInfo {
    top: -4em;
    left: 4.5em;
  }
  .user_main_userInfo > div:nth-of-type(1) {
    width: 6em;
    height: 6em;
  }
}
@media screen and (min-width: 4000px) and (max-width: 4500px) {
  .user_main_tabLeft{
    height: 720px;
  }
  .user_main_tabLeftItem {
    font-size: 36px;
  }
  .user_main_userInfo {
    top: -4em;
    left: 11em;
  }
  .user_main_userInfo > div:nth-of-type(1) {
    width: 6em;
    height: 6em;
  }
}
</style>