<template>
  <div class="my_collect">
    <div class="tabs">
      <div class="tab" :class="[{'active': tabIndex === 1}]" @click="changeTab(1, '画板')">{{boardCount > 0 ? boardCount : ''}} 画板</div>
      <div class="tab" :class="[{'active': tabIndex === 2}]" @click="changeTab(2, '素材')">{{collectCount > 0 ? collectCount : ''}} 素材</div>
      <div class="tab" :class="[{'active': tabIndex === 3}]" @click="changeTab(3, '喜欢')">{{collectCount > 0 ? collectCount : ''}} 喜欢</div>
      <!-- <div class="tab" :class="[{'active': tabIndex === 4}]" @click="changeTab(4, '标签')">{{tagCount > 0 ? tagCount : ''}} 标签</div> -->
      <div class="search">
        <input type="search" :placeholder="'搜索我的' + searchType" v-model="input" @keyup.enter="search">
        <i class="el-icon-search flag" @click="search"></i>
      </div>
    </div>
    <div class="main_part">
      <div class="board_wapper" v-if="tabIndex === 1">
        <user-draw-board :span="6" :data="boardList"></user-draw-board>
      </div>
      <div class="collect_wapper" v-if="tabIndex === 2">
        <my-waterfall :column="4" :data="collectList" @loadmore="loadmore">
          <template v-slot:buttonTR="{ scope }">
            <el-button type="success" size="small" @click.stop="editCollect(scope)">编辑</el-button>
          </template>
        </my-waterfall>
        <el-dialog title="编辑素材" :visible.sync="editVisible" :close-on-click-modal="closeOnClickModal">
          <el-container>
            <el-container>
              <el-main>
                <div class="base_info">
                  <div class="tags_box">
                    <div class="title">标签:</div>
                    <div class="tags">
                      <el-tag v-for="(tag, index) in collect.tags" :key="index" closable type="info" @close="handleClose(tag)">{{tag}}</el-tag>
                      <el-input
                        class="input-new-tag"
                        v-if="tagVisible"
                        v-model="tagText"
                        ref="saveTagInput"
                        size="small"
                        @keyup.enter.native="generateTag"
                        @blur="generateTag"
                      >
                      </el-input>
                      <el-button v-else class="button-new-tag" size="small" @click="showInputTag">+ 新标签</el-button>
                    </div>
                  </div>
                  <div class="info_box">
                    <div class="title">描述:</div>
                    <el-input type="textarea" resize="none" placeholder="请输入描述文本" v-model="collect.info" maxlength="30" show-word-limit></el-input>
                  </div>
                  <div class="board_box">
                    <div class="title">画板:</div>
                    <el-select v-model="collect.board_id" placeholder="请选择画板">
                      <el-option v-for="item in userBoard" :key="item.id" :label="item.name" :value="item.id"></el-option>
                    </el-select>
                  </div>
                </div>
              </el-main>
              <el-aside>
                <div class="edit_preview">
                  <el-image :src="collect.url">
                    <div slot="error" class="image-slot">
                      <i class="el-icon-picture-outline"></i>
                    </div>
                  </el-image>
                </div>
              </el-aside>
            </el-container>
            <el-footer>
              <div class="actions">
                <el-button type="primary">保存</el-button>
                <el-button type="info" @click="editVisible = false">取消</el-button>
                <el-button type="danger">删除</el-button>
              </div>
            </el-footer>
          </el-container>
        </el-dialog>
      </div>
      <div v-if="tabIndex === 3">
        <my-waterfall :column="4" :data="collectList" @loadmore="loadmore">
        </my-waterfall>
      </div>
    </div>
  </div>
</template>

<script>
import MyWaterfall from '@/components/MyWaterfall';
import UserDrawBoard from '@/components/UserDrawBoard';
export default {
  components: {
    MyWaterfall,
    UserDrawBoard,
  },
  data() {
    return {
      tabIndex: 1,
      input: '',
      searchType: '画板',
      boardList: [],
      boardReq: {title: '', use: 0, username: '', cat_id: ''},
      boardCount: 0,
      collectList: [],
      collectReq: {cat_id: '', username: '', ord: 0, title: '', use: 0, page: 1},
      collectCount: 0,
      lockBgColor: ['#8dfff7', '#ecc9c7', '#d1cfc0', '#abf9d0', '#c49ac7', '#93dd9b', '#c49ac7', '#b6e284', '#9adbf9', '#70d2db'],
      likeList: [],
      likePage: 1,
      likeCount: 0,
      tagList: [],
      tagPage: 1,
      tagCount: 0,
      editVisible: false,
      closeOnClickModal: false,
      collect: { id: '', url: '', info: '', board_id: 1, tags: [] },
      tagVisible: false,
      tagText: '',
      userBoard: []
    }
  },
  methods: {
    changeTab(num, text) { // 切换tab栏
      this.tabIndex = num;
      this.searchType = text;
    },
    getCollectData() {  // 素材数据
      this.$service.DEFAULT({
        url: '/auth/ppt/fodder/list',
        method: 'GET',
        params: {
          cat_id: this.collectReq.cat_id,
          username: this.collectReq.username,
          ord: this.collectReq.ord,
          title: this.collectReq.title,
          use: this.collectReq.use,
          page: this.collectReq.page,
        },
      }).then(res => {
        if (res.code == 200) {
          this.collectCount = res.data.count;
          this.collectList = this.collectList.concat(res.data.res);
        }
      })
    },
    loadmore() {  // 加载更多素材
      this.collectReq.page += 1;
      this.getCollectData();
    },
    getBoardData() {  // 画板数据
      this.$service.DEFAULT({
        url: '/auth/ppt/board/list',
        method: 'GET',
        params: {
          title: this.boardReq.title,
          use: this.boardReq.use,
          username: this.boardReq.username,
          cat_id: this.boardReq.cat_id,
        },
      }).then(res => {
        if (res.code == 200) {
          this.boardCount = res.data.count;
          this.boardList = this.boardList.concat(res.data.res);
        }
      })
    },
    search() {  // 搜索
      if (this.tabIndex == 1) {
        this.boardReq.title = this.input;
        this.boardList = [];
        this.getBoardData();
      }
      if (this.tabIndex == 2) {
        this.collectReq.title = this.input;
        this.collectReq.page = 1;
        this.collectList = [];
        this.getCollectData();
      }
      if (this.tabIndex == 3) {
        this.collectReq.title = this.input;
        this.collectReq.page = 1;
        this.collectList = [];
        this.getCollectData();
      }
    },
    editCollect(data) { // 编辑素材
      this.editVisible = true;
      this.collect.id = data.id ? data.id : '';
      this.collect.url = data.url ? data.url : '';
      this.collect.info = data.info ? data.info : '';
      this.collect.board_id = data.board_id ? data.board_id : 2;
      this.collect.tags = data.tags ? data.tags : ['哈哈哈', '嘻嘻嘻'];
    },
    handleClose(tag) {  // 删除标签
      this.collect.tags.splice(this.collect.tags.indexOf(tag), 1);
    },
    showInputTag() {  // 显示标签输入框
      this.tagVisible = true;
      this.$nextTick(_ => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    generateTag() { // 新增素材标签
      let tagText = this.tagText;
      if (tagText) {
        this.collect.tags.push(tagText);
      }
      this.tagVisible = false;
      this.tagText = '';
    },

  },
  created() {
    // this.getCollectData();
    // this.getBoardData();
  },
}
</script>

<style lang="scss" scoped>
.el-tag + .el-tag {
  margin-left: 10px;
}
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}

.my_collect {
  background: #f5f5f5;
  .tabs {
    background: #fff;
    border: 1px solid #ededed;
    position: relative;
    margin-bottom: 10px;
    .tab {
      font-size: 14px;
      height: 48px;
      line-height: 48px;
      padding: 0 20px;
      color: #777;
      display: inline-block;
      cursor: pointer;
      &:hover {
        color: #9a0000;
      }
    }
    .active {
      background: #fff;
      color: #444;
      font-weight: 700;
    }
    .search {
      height: 32px;
      font-size: 14px;
      color: #bbb;
      background: #fafafa;
      border: 1px solid #ddd;
      border-radius: 2px;
      position: absolute;
      right: 10px;
      top: 50%;
      margin-top: -17px;
      input {
        width: 183px;
        height: 100%;
        background: #fff;
        border-color: #ececec;
        padding: 0 30px 0 10px;
        color: #999;
        border: none;
        outline: 0;
      }
      .flag {
        position: absolute;
        right: 6px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 20px;
        cursor: pointer;
      }
    }
  }
  .main_part {
    .collect_wapper {
      .base_info {
        height: 100%;
        .tags_box {
          margin-bottom: .75rem;
          .title {
            margin-bottom: .3em;
          }
          .tags {
            padding: 1rem;
            border: 1px solid #ededed;
            border-radius: 1%;
            min-height: 100px;
          }
        }
        .info_box {
          margin-bottom: .75rem;
          .title {
            margin-bottom: .3em;
          }
        }
        .board_box {
          .title {
            margin-bottom: .3em;
          }
          .el-select {
            width: 100%;
          }
        }
      }
      .edit_preview {
        max-height: 50vh;
        .el-image {
          border: 1px solid #ccc;
        }
      }
      .actions {
        text-align: center;
      }
    }
  }
}
</style>